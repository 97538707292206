import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AxilogService } from './_services/axilog.service';
import { SettingsService } from './_services/settings.service';
import { StatesService } from './shared/services/states.service';
import { ConfigService } from 'src/app/_services/config.service';
import { SnackbarService } from 'ngx-snackbar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SnackBarOptions } from 'src/app/_models';
import {Title} from '@angular/platform-browser';


declare var jQuery: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  providers: [SettingsService],
})

export class AppComponent implements OnDestroy, OnInit {

  favIcon: HTMLLinkElement = document.querySelector('#faviconHeader');

  @HostBinding('class') classList: string;

  @HostListener('click', ['$event']) onClick($event) {
    if (!jQuery($event.target).parents('.menu-user-block').length && this.statesService.app.hasClass('info_isopen')) {
      this.statesService.app.removeClass('info_isopen');
    }
  }

  private _unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private title: Title,
    private axilogService: AxilogService,
    private statesService: StatesService,
    private _configService: ConfigService,
    private _snackbarService: SnackbarService,
    private ss: SettingsService,
  ) {

    axilogService.init();

    ss.getSettings().subscribe(
      res => {
        if (!res){
          return;
        }
        title.setTitle(res.title);
        if (res.favicon) {
          this.favIcon.href = res.favicon;
        }
      }
    )
    this.statesService.app.subject.subscribe(() => {
      this.classList = this.statesService.app.getClassList();
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  ngOnInit(): void {
    this._configService.showSnackBar$
      .pipe(
        takeUntil(this._unsubscribe$)
      )
      .subscribe((res: SnackBarOptions) => {
        this._snackbarService.add({
          msg: res.msg,
          action: {
            text: res.actionText,
          },
        });
      });
  }
}
