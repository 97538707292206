import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ConfigService } from '../../_services/config.service';
import { SettingsService } from '../../_services/settings.service';

@Component({
  selector: 'agreement',
  templateUrl: './agreement.html',
  providers: [SettingsService],
})

export class AgreementComponent {
  public text;
  siteName = this.cfg.siteName;

  constructor(public router: Router, title: Title, private cfg: ConfigService, private ss: SettingsService) {
    ss.getSettings().subscribe(res=>{
      this.text = res.agreement;
      title.setTitle('Пользовательское соглашение - ' + ss.siteName);
    });
    
  }
}
