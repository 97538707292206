import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AdminGuard, AuthGuard } from './_guards';
import { PageNotFoundComponent } from './page-not-found/page-not-found';
import { AgreementComponent } from './public/agreement/agreement';
import {PubStatsStream} from "./pubStats/stream/stream";

const routes: Routes = [
    {
        path: 'agreement',
        component: AgreementComponent
    }, {
        path: 'auth',
        loadChildren: './auth/auth.module#AuthModule',
    }, {
        path: 'cabinet',
        loadChildren: './cabinet/cabinet.module#CabinetModule',
        canLoad: [AuthGuard]
    }, {
        path: 'admin',
        loadChildren: './admin/admin.module#AdminModule',
        canLoad: [AdminGuard]
    }, {
        path: 'pub-stats/:id/:key',
        component: PubStatsStream,

    }, {
      path: 'pub-stats',
      component: PubStatsStream,

    },{
      path: '',
      redirectTo: 'cabinet',
      pathMatch: 'full'
    }, {
        path: '**',
        component: PageNotFoundComponent
    }

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}
