import {Component} from '@angular/core';


@Component({
    selector: 'pubStats',
    templateUrl: './pubStats.component.html',
})

export class PubStats {
}
