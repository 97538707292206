import {Component, NgZone, OnInit, OnDestroy} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {PubStatisticsService} from "../../_services/pub.statistics.service";
import {SHelper} from "../../_helpers/SHelper";
import * as Moment from "moment";
import {ConfigService} from "../../_services/config.service";

declare var jQuery: any;

@Component({
    selector: 'pubStatsStream',
    templateUrl: './stream.html',
    providers: [PubStatisticsService]
})

export class PubStatsStream implements OnInit, OnDestroy {
    statisticLoaded: Boolean = false;
    public titleHeader: string;
    public group: string;
    public conversions: any = [];

    public data: any = {};
    private timer: any;
    public currentUrl: string;

    extId: number = 0;

    public link:string;
    public linkloading:boolean;

    private params: any;
    private streamid: any;
    private password: any;
    public login: any;
    private month: any;
    private filters: any;
    public monthname: any;
    public monthlist = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

    constructor(title: Title, route: ActivatedRoute, private  router: Router, private  zone: NgZone, private statistics: PubStatisticsService, cfg: ConfigService) {

        this.titleHeader = 'Потоки';
        this.group = 'date';
        this.conversions = [];
        title.setTitle("По потокам - " + cfg.siteName);

        this.filters = {date:{
                start: Moment().startOf('month').toDate(),
                end: Moment().endOf('month').toDate()
            }};

        this.month = 0;
        this.monthname = this.monthlist[new Date().getMonth()];


        this.params = route.params.subscribe((params) => {
            this.streamid = params["id"];
            this.password = params["key"];
            this.login = params["id"] && params["key"];
        });
    }

    ngOnInit() {
        jQuery('#preloader').hide();

        this.initData();
        this.timer = setInterval(() => {
                this.getStatistics();
        }, 5000);

        this.getStatistics();
    }

    ngOnDestroy() {
        if (this.timer) {
            clearInterval(this.timer)
        }
    }

    initData() {

        if (!this.data) {
            this.data = {
                rows: [],
                all: {},
                lastFixId: 1
            }
        }
        if (!this.data.lastFixId) {
            this.data.lastFixId = 1;
        }
        if (!this.data.rows) {
            this.data.rows = [];
            this.data.lastFixId = 1;
        }
    }

    onSubTableFilter(e:any) {}

    getStatistics() {
        if (this.statisticLoaded) return;
        this.statisticLoaded = true;

        this.extId++;

            let filters: any = JSON.parse(JSON.stringify(this.filters));

            if (!filters) {
                filters = {};
            }

            filters.group = this.group;

            filters.streamid = this.streamid;
            filters.password = this.password;

            let filtersMD5 = SHelper.md5(JSON.stringify(filters));

            filters.date.start = (Moment(filters.date.start).startOf('day').utcOffset(+3, true).utcOffset(0, false).toDate().getTime() / 1000) >> 0;
            filters.date.end = (Moment(filters.date.end).endOf('day').utcOffset(+3, true).utcOffset(0, false).toDate().getTime() / 1000) >> 0;

            filters.clientUtcOffset = Moment().utcOffset().toFixed();
            filters.extid = this.extId;

            if (this.data.filtersMD5 != filtersMD5) {
                // console.log('getStatistics');
                this.showLoader();
                this.data.lastFixId = 0;
                filters.lastFixId = this.data.lastFixId;

            } else {
                // console.log('update');
                filters.lastFixId = this.data.lastFixId;
            }

            let s1 = this.statistics.getStatisticsFromPostgres(filters).subscribe(
                res => {
                    if (res) {
                        // console.log(res)
                        if (this.data.filtersMD5 != filtersMD5) {
                            this.data.filtersMD5 = filtersMD5;
                            this.newData(res);
                        } else {
                            if (this.extId == res.ext) {
                                this.updateData(res);
                            }
                        }
                        this.statisticLoaded = false;
                        this.hideLoader();
                        if (res && res.row && res.row.length > 0 || res && res.rowFix && res.rowFix.length > 0) {
                            this.zone.run(() => {
                            });
                        }
                    } else {
                        this.login = false;
                    }
                },
                error => {
                    console.log('что-то пошло не так...');
                    console.log(error);

                    if (this.data.filtersMD5 != filtersMD5) {
                        this.initData();
                    }
                    setTimeout(() => {
                        this.statisticLoaded = false;
                        this.hideLoader();
                    }, 10000)
                }
            );
    }

    newData(res:any) {
        this.initData();

        if (res.row.length > 0) {
            this.data.rows = res.row;

            this.data.all = this.rowEmpty()
            for (let row of res.row) {
                this.data.all = this.rowAdd(this.data.all, row);
                if (this.data.lastFixId < row.max_id) {
                    this.data.lastFixId = row.max_id;
                }
            }
        } else {
            this.data.rows = [];
            this.data.all = this.rowEmpty()
            this.data.lastFixId = 1;
        }
    }

    updateData(res:any) {
        if (res && res.rowFix && res.rowFix.length > 0) {
            for (let j = 0; j < res.rowFix.length; j++) {
                let found = false;
                if (this.data.rows.length) {
                    for (let i = 0; i < this.data.rows.length; i++) {
                        if (this.data.rows[i].key == res.rowFix[j].key) {
                            this.data.rows[i] = this.rowAdd(this.data.rows[i], res.rowFix[j]);
                            this.data.all = this.rowAdd(this.data.all, res.rowFix[j]);
                            found = true;
                            break
                        }
                    }
                }
                if (!found) {
                    this.data.rows.unshift(res.rowFix[j]);
                    this.data.all = this.rowAdd(this.data.all, res.rowFix[j]);
                }
                if (this.data.lastFixId < res.rowFix[j].max_id) {
                    this.data.lastFixId = res.rowFix[j].max_id;
                }
            }
        }
    }


    rowAdd(a: any, b: any) {
        a.c_all += b.c_all;
        a.c_approved += b.c_approved;
        a.c_pending += b.c_pending;
        a.c_rejected += b.c_rejected;
        a.c_trash += b.c_trash;
        a.clicks += b.clicks;
        a.f_all += b.f_all;
        a.f_approved += b.f_approved;
        a.f_pending += b.f_pending;
        a.f_rejected += b.f_rejected;
        a.hits += b.hits;
        a.tback += b.tback;
        if (b.custom_hits) {
            a.custom_hits += b.custom_hits;
        }
        if (b.landing) {
            a.landing += b.landing;
        }
        if (b.transit) {
            a.transit += b.transit;
        }
        if (b.bots) {
            a.bots += b.bots;
        }

        return a;
    }

    rowEmpty() {
        return {
            title: 'Всего:',
            c_all: 0,
            c_approved: 0,
            c_pending: 0,
            c_rejected: 0,
            c_trash: 0,
            clicks: 0,
            f_all: 0,
            f_approved: 0,
            f_pending: 0,
            f_rejected: 0,
            hits: 0,
            custom_hits: 0,
            tback: 0,
            landing: 0,
            transit: 0,
            bots: 0
        }
    }

    showLoader() {
        jQuery('#pre2loader2').show();
        jQuery('#pre2loader2_notshow').hide();
    }

    hideLoader() {
        jQuery('#pre2loader2').hide();
        jQuery('#pre2loader2_notshow').show();
    }

    public paginator (znak:Number) {
        this.month = this.month + znak;
        this.filters.date.start = Moment().startOf('month').toDate();
        this.filters.date.end = Moment().endOf('month').toDate();
        this.filters.date.start.setMonth(this.filters.date.start.getMonth() + this.month);
        this.filters.date.end.setMonth(this.filters.date.end.getMonth() + this.month);
        this.monthname = this.monthlist[this.filters.date.start.getMonth()];
    }

}
