import { HttpEvent, HttpHandler, HttpRequest, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {

  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')).token : null;

    if (token) {
      req = req.clone({headers: req.headers.set('Authorization', token)});
    }

    if (!req.headers.has('Content-Type') && !(req.body instanceof FormData)) {
      req = req.clone({headers: req.headers.set('Content-Type', 'application/json')});
    }

    if (!req.headers.has('Accept')) {
      req = req.clone({headers: req.headers.set('Accept', 'application/json')});
    }

    return next.handle(req)
      .pipe(
        tap((evt: HttpEvent<any>) => {
            if (evt instanceof HttpResponse) {
              // TODO something with response
            }
            return evt;
          },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401 || err.status === 403) {
                this._authenticationService.logout();
              }
            }
          })
      );
  }
}
