import {Component} from '@angular/core';
import {LocalStorageService} from "ngx-webstorage";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: '.pageNotFound',
    templateUrl: './page-not-found.html'
})

export class PageNotFoundComponent {

    constructor(private localStorage: LocalStorageService,
                private translate: TranslateService) {}

    switchLanguage(lang: string) {
        this.localStorage.store('default-language', lang);
        this.translate.use(lang);
    }
}
