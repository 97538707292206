import { Inject, Injectable } from '@angular/core';

import { WINDOW } from './window.service';

@Injectable({
    providedIn: 'root'
})
export class AxilogService {
    private static Instance: boolean;

    constructor(@Inject(WINDOW) private window: Window) {
    }

    init() {
        // @ts-ignore
        if (this.window.env.host.production && !AxilogService.Instance) {
            // TODO: add declaration for class
            // @ts-ignore
            new this.window.ClientActionJS({
                apiUrl: 'https://axilog.lm23.net/log',
                ignoreFields: ['#password'],
                token: 'cabinet.leadbrothers.co'
            });

            AxilogService.Instance = true;
            // @ts-ignore
        } else if (this.window.env.host.production && AxilogService) {
            throw new Error('AxilogService initiated');
        }
    }
}
