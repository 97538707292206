﻿import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {map} from 'rxjs/operators';
import {BaseService} from "./base.service";

@Injectable()
export class PubStatisticsService {

    constructor(private BS: BaseService) {
    }

    getStatisticsFromPostgres(filters: any): Observable<any> {
        return this.BS.httpPost('guest/stats', filters)
          .pipe(
            map((response: any) => {
                const res = response;
                if (res && res.valid) {
                    return res.result;
                }
                return false;
            })
          );
    }

}
