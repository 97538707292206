import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PUB_DECLARATIONS} from '../../app/pubStats'
import {TranslateModule} from '@ngx-translate/core';
import { NumberFormatPipe } from '../pipes/number-format.pipe';

@NgModule({
  declarations:[
    ...PUB_DECLARATIONS,
    NumberFormatPipe
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        NumberFormatPipe
    ]
})

export class SharedModule {
}
