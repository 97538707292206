﻿import { Inject, Injectable } from '@angular/core';

import { WINDOW } from './window.service';
import { SnackbarService } from 'ngx-snackbar';
import { Subject } from 'rxjs';
import { SnackBarOptions } from 'src/app/_models';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  apiUrl: string;
  isEmpire: boolean;
  siteBonus: string;
  siteLogo: string;
  siteName: string;
  trackerUrl: string;
  static inited: number;
  showSnackBar$: Subject<SnackBarOptions> = new Subject<SnackBarOptions>();

  constructor(
    @Inject(WINDOW) private window: Window,
    private _snackbarService: SnackbarService,
  ) {
    if (!ConfigService.inited) {
      ConfigService.inited = 0;
    }
    // @ts-ignore
    this.apiUrl = `${environment.host}${environment.api}`;
    this.isEmpire = false;
    this.siteLogo = '/public/images/general/logo_merix.svg';
    this.siteName = 'Cpamerix';
    this.trackerUrl = 'cpamerix.so';
    ConfigService.inited++;
  }
}
