import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

type StateSubject = BehaviorSubject<State>;

interface State {
    classes: string[];
}

@Injectable({
    providedIn: 'root'
})
export class StatesService {
    app: StateMethods;

    constructor() {
        this.app = new AppState();
    }
}

class StateMethods {
    subject: StateSubject;

    constructor() {
        this.subject = new BehaviorSubject({classes: []});
    }

    addClass(className: string): void {
        this.applyMethods(this.PrivateAddClass(className));
    }

    private PrivateAddClass(className: string): State {
        return {classes: this.subject.getValue().classes.concat(className.split(' '))};
    }

    removeClass(className: string): void {
        this.applyMethods(this.PrivateRemoveClass(className));
    }

    private PrivateRemoveClass(className: string): State {
        const classNameList = className.split(' ');

        return {
            classes: this.subject.getValue().classes.filter(name => {
                return !classNameList.includes(name);
            })
        };
    }

    getClassList(): string {
        return this.subject.getValue().classes.join(' ') || '';
    }

    hasClass(className: string): boolean {
        const classNameList = className.split(' ');

        return classNameList.every(name => {
            return this.subject.getValue().classes.includes(name);
        });
    }

    toggleClass(fromClassName: string, toClassName?: string): void {
        const fromClassNameList = fromClassName.split(' ');
        const toClassNameList = toClassName ? toClassName.split(' ') : undefined;

        if (toClassName && (fromClassNameList.length !== toClassNameList.length)) {
            return;
        }

        if (!toClassName) {
            const addList = [];
            const removeList = [];
            let newAddList: State = null;
            let newRemoveList: State = null;

            fromClassNameList.forEach(className => {
                this.hasClass(className) ? removeList.push(className) : addList.push(className);
            });

            if (removeList.length)  {
                newRemoveList = this.PrivateRemoveClass(removeList.join(' '));
            }

            if (addList.length)  {
                newAddList = this.PrivateAddClass(addList.join(' '));
            }

            this.applyMethods(Object.assign({classes: []}, newRemoveList || {}, newAddList || {}));
        } else {
            const classes = this.subject.getValue().classes;

            fromClassNameList.forEach((name, i) => {
                if (classes.includes(name)) {
                    const index = classes.findIndex(classItem => {
                        return classItem === name;
                    });

                    classes[index] = toClassNameList[i];
                }
            });

            this.applyMethods({classes});
        }
    }

    private applyMethods(subject: State): void {
        this.subject.next(subject);
    }
}

class AppState extends StateMethods {
    subject: StateSubject;

    constructor() {
        super();
    }
}
