import {Component} from '@angular/core';


@Component({
    selector: 'pubMainMenu',
    templateUrl: './main-menu.html',
})

export class PubMainMenuDirective {
}
