﻿import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

import { Observable, throwError } from 'rxjs';

import { ConfigService } from './config.service';
import { SHelper } from '../_helpers/SHelper';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public token: string;
  public userId: string;
  public fake: boolean;
  public responseRegister: any;
  public responseRestore: any;
  private requestOptions: any;
  public getDataToken;

  constructor(
    private http: HttpClient,
    private cfg: ConfigService,
    private router: Router,
    private jwtHelper: JwtHelperService,
  ) {
    // setFiltres token if saved in local storage
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
    this.userId = currentUser && currentUser.email;
    this.fake = (currentUser && currentUser.fake) ? currentUser.fake : false;

    this.requestOptions = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    this.setDataToken();
  }

  public getResponse(propName: string) {
    const resProp = this[propName];
    return resProp ? resProp : false;
  }

  public getEmailProvider(propName: string) {
    const getRes = this.getResponse(propName);
    return getRes && getRes.email_provider ? 'http://' + getRes.email_provider : false;
  }

  private setDataToken() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      this.getDataToken = this.jwtHelper.decodeToken(currentUser.token);
    }
  }

  login(email: string, password: string): Observable<{status: boolean, error?: string}> {
    localStorage.removeItem('currentUser');

    const data = SHelper.base64_encode(JSON.stringify({email, password}));

    return this.http.post(this.cfg.apiUrl + '/auth/login', JSON.stringify({data}), this.requestOptions)
      .pipe(
        map((response: any) => {
          // login successful if there's a jwt token in the response
          const token = response && response.token;
          if (token) {
            // setFiltres token property
            this.token = token;

            // store username and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify({email, token}));

            this.setDataToken();

            // return true to indicate successful login
            return {status: true};
          } else {
            // return false to indicate failed login
            return {status: false, error: response.error};
          }
        }),
        catchError((error: any) => throwError(error)),
      );
  }

  fakelogin(hash: string): Observable<boolean> {
    localStorage.removeItem('currentUser');

    return this.http.post(this.cfg.apiUrl + '/auth/fake', JSON.stringify({hash: hash}), this.requestOptions)
      .pipe(
        map((response: any) => {
          // login successful if there's a jwt token in the response
          const token = response && response.token;
          const email = response && response.email;
          if (token && email) {
            // setFiltres token property
            this.token = token;

            // store username and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify({email: email, token: token, fake: true}));

            this.setDataToken();

            // return true to indicate successful login
            return true;
          } else {
            // return false to indicate failed login
            return false;
          }
        }),
        catchError((error: any) => throwError(error)),
      );
  }

  userCreate(form: any, rid?: string, ec?: string): Observable<{valid: boolean, error?: string}> {

    const data = SHelper.base64_encode(JSON.stringify({
      email: form.email,
      password: form.password,
      name: encodeURI(form.username),
      phone: form.phone.replace(/[^+0-9]*/g, ''),
      click: ec,
      rid,
      // contacts: [{type: 'Skype', contact: form.skype}]
    }));

    return this.http.post(this.cfg.apiUrl + '/auth/register', JSON.stringify({data: data}), this.requestOptions)
      .pipe(
        map((response: any) => {
          const res = response;
          if (res && res.valid) {
            this.responseRegister = res;
            return {valid: true};
          } else {
            return {valid: false, error: res.error || null};
          }
        }),
        catchError(error => throwError(error)),
      );
  }

  emailAlreadyExist(email: string): Observable<boolean> {

    return this.http.post(this.cfg.apiUrl + '/user/emailex', JSON.stringify({email: email}), this.requestOptions)
      .pipe(
        map((response: any) => {
          const res = response;
          if (res && res.valid && res.result) {
            return true;
          }
          return false;
        }),
        catchError((error: any) => throwError(error)),
      );
  }

  checkEmail(email: string): Observable<boolean> {
    const url = 'https://cabinet.leadbrothers.co/testmail.php';
    const options = {
      params: new HttpParams()
    };
    options.params.set('mail', email);
    return this.http.get(url, options)
      .pipe(
        map((response: any) => {
          const res = response;
          if (res && res.data && res.data.check) {
            return true;
          }
          return false;
        })
      );
  }

  logout(): void {
    // clear token remove user from local storage to log user out

    this.token = null;
    localStorage.removeItem('currentUser');
    this.router.navigate(['/auth', 'login']);
  }

  logoutSendApi(): void {
    this.http.post(this.cfg.apiUrl + '/auth/logout', JSON.stringify({}), this.requestOptions)
      .pipe(map((response: any) => {}), catchError((error: any) => throwError(error))).subscribe(result => {});
  }

  forgotPassword(email: string): Observable<boolean> {

    return this.http.post(this.cfg.apiUrl + '/auth/restore', JSON.stringify({email: email}), this.requestOptions)
      .pipe(
        map((response: any) => {
          const res = response;
          if (res && res.valid) {
            this.responseRestore = res;
            return res;
          }
          return res;
        }),
        catchError((error: any) => throwError(error)),
      );
  }

  verifyEmail(token: string): Observable<{status: number, phone?: string}> {
    return this.http.post(this.cfg.apiUrl + '/auth/verify', JSON.stringify({hash: token}), this.requestOptions)
      .pipe(
        map((res: any) => {
          if (res && res.valid) {
            return {status: 200, phone: res.phone || null};
          }
          return {status: 404};
        }),
        catchError((error: any) => throwError(error)),
      );
  }

  fetchPhoneCode(token: string, phone: string): Observable<{valid: boolean, error?: string}> {
    return this.http.post(this.cfg.apiUrl + '/auth/code/fetch', JSON.stringify({
      hash: token,
      phone: phone.replace(/[^+0-9]*/g, '')
    }), this.requestOptions)
      .pipe(
        map((res: any) => {
          return {
            valid: !!res && res.valid,
            error: !!res && res.error || null
          };
        }),
        catchError((error: any) => throwError(error)),
      );
  }

  sendPhoneCode(token: string, code: string): Observable<{valid: boolean, error?: string}> {
    return this.http.post(this.cfg.apiUrl + '/auth/code/send', JSON.stringify({hash: token, code}), this.requestOptions)
      .pipe(
        map((res: any) => {
          return {
            valid: !!res && res.valid,
            error: !!res && res.error || null
          };
        }),
        catchError((error: any) => throwError(error)),
      );
  }

  resetPassword(token: string, password: string): Observable<boolean> {

    return this.http.post(this.cfg.apiUrl + '/auth/password', JSON.stringify({hash: token, password: password}), this.requestOptions)
      .pipe(
        map((response: any) => {
          const res = response;
          console.log(res);
          if (res && res.valid) {
            return true;
          }
          return false;
        }),
        catchError((error: any) => throwError(error)),
      );
  }

  checkHash(token: string): Observable<boolean> {

    return this.http.post(this.cfg.apiUrl + '/auth/newpass', JSON.stringify({hash: token}), this.requestOptions)
      .pipe(
        map((response: any) => {
          const res = response;
          console.log(res);
          if (res && res.valid) {
            return true;
          }
          return false;
        }),
        catchError((error: any) => throwError(error)),
      );
  }

  retrackUser(default_params: object): Observable<boolean> {
    // let params = new URLSearchParams();

    const options = {
      params: new HttpParams()
    };
    // options.search.set('aff', default_params['aff']);

    options.params.set('aff', default_params['aff']);
    options.params.set('token', default_params['token']);
    options.params.set('status', default_params['status']);
    options.params.set('email', default_params['email']);
    options.params.set('name', default_params['name']);
    options.params.set('conversion', default_params['conversion']);
    // let options = new RequestOptions({search: params});

    return this.http.get('https://retrack.leadmafia.co/cpa', options)
      .pipe(
        map((response: any) => {
          console.log(response);
          return true;
        })
      );
  }
}
