﻿import { Injectable } from "@angular/core";
import * as internal from "assert";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BaseService } from "./base.service";
import { ConfigService } from "./config.service";

@Injectable()
export class SettingsService {
  public title: string;
  public siteName: string;
  public supportMail: string;
  public telegram: string;
  public utcOffset: number = 3;
  public rules: string;
  public phoneRequired: boolean;
  public modeRation: boolean;
  public agreement: boolean;
  public logo: string;
  public faq: string;

  private res: any;

  private loaded: boolean;

  constructor(private BS: BaseService, private CS: ConfigService) {
    this.getSettings().subscribe((res) => {
      this.parseRes(res);
    });
  }
  loadCache(): boolean {
    const cache = window.sessionStorage.getItem("SettingsService_data");
    if (cache === null) {
      return false;
    }
    this.parseRes(
      JSON.parse(window.sessionStorage.getItem("SettingsService_data"))
    );
    return true;
  }
  saveCache(res) {
    const data = {
      title: res.title,
      siteName: res.siteName,
      supportMail: res.supportMail,
      telegram: res.telegram,
      utcOffset: res.utcOffset,
      rules: res.rules,
      phoneRequired: res.phoneRequired,
      modeRation: res.modeRation,
      agreement: res.agreement,
      logo: res.logo,
      faq: res.faq,
    };
    window.sessionStorage.setItem("SettingsService_data", JSON.stringify(data));
  }
  parseRes(res) {
    this.title = res.title || "title";
    this.siteName = res.siteName || "siteName";
    this.supportMail = res.supportMail || "supportMail";
    this.utcOffset = res.utcOffset || "utcOffset";
    this.rules = res.rules || "rules";
    this.phoneRequired = res.phoneRequired || "phoneRequired";
    this.modeRation = res.modeRation || "modeRation";
    this.agreement = res.agreement || "agreement";
    this.logo = res.logo || "logo";
    this.faq = res.faq || "";
    this.CS.siteName = this.title;
    this.CS.siteLogo = this.logo;
    this.loaded = true;
    this.res = res;
  }
  getSettings(): Observable<any> {
    if (this.loaded) {
      return new Observable((subscriber) => {
        subscriber.next(this.res);
      });
    }
    if (this.loadCache()) {
      return new Observable((subscriber) => {
        subscriber.next(this.res);
      });
    }
    return this.BS.httpGet("settings/").pipe(
      map((response: any) => {
        this.parseRes(response);
        this.saveCache(response);
        return response;
      })
    );
  }
}
