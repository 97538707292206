﻿import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { AuthenticationService } from './authentication.service';
import { ConfigService } from './config.service';

declare var jQuery: any;

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  error: boolean;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private cfg: ConfigService,
  ) {
  }

  httpPost(api: string, data: any, options?, isV3?: boolean): Observable<any> {
    if (options && typeof options === 'boolean') {
      isV3 = options;
    }

    if (!(data instanceof FormData)) {
      data = JSON.stringify(data);
    }

    return this.http.post(`${this.cfg.apiUrl}/${isV3 ? 'v3' : 'v2'}/${api}`, data, options)
      .pipe(
        map((response: any) => {
          if (this.error) {
            this.error = false;
            jQuery('#connectionsError').hide();
          }
          return response;
        }),
        catchError(error => throwError(error)),
      );
  }

  httpGet(params: string, isV3?: boolean, fullResponse?: boolean) {
    return this.http.get(`${this.cfg.apiUrl}/${isV3 ? 'v3' : 'v2'}/${params}`)
      .pipe(
        map((response: any) => response.result && !fullResponse ? response.result : response),
        catchError(error => throwError(error)),
      );
  }


  httpDelete(params: string, isV3?: boolean) {
    return this.http.delete<{}>(`${this.cfg.apiUrl}/${isV3 ? 'v3' : 'v2'}/${params}`)
      .pipe(
        catchError(error => throwError(error)),
      );
  }


  httpPut(api: string, body: any, isV3?: boolean) {
    return this.http.put<any>(`${this.cfg.apiUrl}/${isV3 ? 'v3' : 'v2'}/${api}`, body)
      .pipe(
        catchError(error => throwError(error)),
      );
  }
}
