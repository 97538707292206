import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { RatingModule } from 'ngx-rating';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
//import { PUB_DECLARATIONS } from './pubStats';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { InterceptorService } from './shared/interceptor/interceptor.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found';
import { WINDOW_PROVIDERS } from './_services/window.service';
import { SharedModule } from './shared/shared.module';
import { AgreementComponent } from './public/agreement/agreement';
import { SnackbarModule } from 'ngx-snackbar';

export function tokenGetter() {
    return localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')).token : '';
}

@NgModule({
    imports: [
        SharedModule,
        BrowserModule,
        ReactiveFormsModule,
        RatingModule,
        HttpClientModule,
        NgxWebstorageModule.forRoot(),
        DeviceDetectorModule.forRoot(),
        SnackbarModule.forRoot(),
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => new TranslateHttpLoader(http, '/public/i18n/', '.json'),
                deps: [HttpClient]
            }
        }),
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter
                // throwNoTokenError: true,
                // skipWhenExpired: true
            }
        })
    ],
    declarations: [
      //  ...PUB_DECLARATIONS,
        AppComponent,
        PageNotFoundComponent,
        AgreementComponent,

    ],
    providers: [
        [{provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true}],
        Title,
        WINDOW_PROVIDERS
    ],
    bootstrap: [
        AppComponent
    ]
})

export class AppModule {
}
